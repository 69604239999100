import React from 'react';
import { useTheme } from '../ThemeManager';
import { MoonCurve, SunMain, SunMin, SunShine } from '../../library/SVG';
import Wrapper from './Wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { useMenu } from '../../library/MenuContext';

export const ThemeToggle: React.FC = () => {

  const theme = useTheme();
  const { themeListOpen, toggleThemeList } = useMenu();

  const { mode, toggleTheme } = theme;

  const handleToggleTheme = (theme: string) => {
    toggleThemeList(false);
    toggleTheme(theme);
  }

  const label = (mode === 'light' || mode === 'dark')
    ? mode.charAt(0).toUpperCase() + mode.slice(1)
    : mode.toUpperCase();

  return (
    <Wrapper theme={theme}>
      <div className='toggle'>
        <button onClick={() => handleToggleTheme('auto')}>
          <SunMain />
          <MoonCurve />
          <SunMin />
          <SunShine />
        </button>
      </div>
      {mode !== 'dark' &&
        <>
          <div className='list' onClick={() => { toggleThemeList(!themeListOpen) }}>
            {label}
            <FontAwesomeIcon icon={themeListOpen ? faAngleUp : faAngleDown} className='caret' />
          </div>
          {themeListOpen &&
            <ul>
              <li onClick={() => handleToggleTheme('light')}>Light</li>
              <li onClick={() => handleToggleTheme('dark')}>Dark</li>
              <li onClick={() => handleToggleTheme('btc')}>BTC</li>
              <li onClick={() => handleToggleTheme('dot')}>DOT</li>
              <li onClick={() => handleToggleTheme('eth')}>ETH</li>
            </ul>
          }
        </>
      }
    </Wrapper>
  );
}

export default ThemeToggle;